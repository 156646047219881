<template>
    <tit-wrap v-if="this.$slots['tit']" :class="this.$slots['subTit'] || titAlign != undefined ? titAlign : 'text-left'">
        <tit-primary :titSize="titSize" :class="this.$slots['subTit'] ? 'd-sm-inline-flex align-center' : ''">
            <slot name="tit" />
            <v-divider v-if="this.$slots['subTit']" vertical class="h-10px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block" />
            <txt-primary v-if="this.$slots['subTit']" txtSize="txt--sm" class="d-block d-sm-inline-block txt--dark pt-8px pt-sm-0"><slot name="subTit" /></txt-primary>
        </tit-primary>
        <txt-primary v-if="this.$slots['txt']" txtSize="txt--sm" class="txt--dark"><slot name="txt" /></txt-primary>
    </tit-wrap>
</template>

<script>
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    props: {
        tit: String,
        subtit: String,
        txt: String,
        titAlign: String,
        titSize: { type: String, default: "" },
    },
    components: {
        TitWrap,
        TitPrimary,
        TxtPrimary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
