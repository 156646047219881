var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', _vm._b({
    staticClass: "container--lg"
  }, 'v-container', _vm.$attrs, false), [_c('v-row', {
    staticClass: "mypage-layout-row"
  }, [_vm.accessToken ? _c('v-col', {
    staticClass: "mypage-layout-row__aside"
  }, [_c('div', {
    staticClass: "mypage-layout-row__aside-inner"
  }, [_c('mypage-aside')], 1)]) : _vm._e(), _c('v-col', {
    staticClass: "mypage-layout-row__contents",
    attrs: {
      "md": _vm.accessToken ? '' : undefined
    }
  }, [_c('div', {
    staticClass: "pb-20px pb-md-40px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('u-tit-wrap-underline', _vm._b({
    staticClass: "mb-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(_vm._s(_vm.title))];
      },
      proxy: true
    }, _vm.subTitle ? {
      key: "subTit",
      fn: function () {
        return [_vm._v(_vm._s(_vm.subTitle))];
      },
      proxy: true
    } : null], null, true)
  }, 'u-tit-wrap-underline', _vm.$attrs, false))], 1), this.$slots['titleRight'] ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._t("titleRight")], 2) : _vm._e()], 1)], 1), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }