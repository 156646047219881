<template>
    <client-page>
        <mypage-layout title="개인정보 수정">
            <v-tabs-items :value="tab" touchless>
                <myinfo-tab-verify @next="tab = 'modify'" />

                <v-tab-item value="modify">
                    <div class="mw-588px">
                        <v-form v-model="isValid">
                            <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs py-0">
                                <template #pageSectionHead>
                                    <v-container>
                                        <txt-primary txtSize="txt--sm" class="text-right mt-0 pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</txt-primary>
                                    </v-container>
                                </template>
                                <template #tit>기업정보</template>
                                <tit-form-section v-model="form" :items="corporations" @certification="(value) => (form._certification = value)">
                                    <template slot="사업자등록번호">
                                        <v-row align="center" class="row--xxs">
                                            <v-col>
                                                <v-text-field v-model="form.company.businessNo" v-bind="{ ...attrs_input, ...$attrs }" placeholder="사업자등록번호 입력" />
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template slot="주소">
                                        <v-address-field v-model="form" outlined persistent-placeholder :btnAttrs="{ large: true, color: 'primary' }" />
                                    </template>
                                </tit-form-section>
                            </page-section-primary>

                            <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs pb-0">
                                <template #tit>개인정보</template>
                                <tit-form-section v-model="form" v-bind="{ items }" @certification="(value) => (form._certification = value)">
                                    <template slot="아이디">
                                        <span class="font-size-14 primary--text">
                                            {{ form?.username }}
                                        </span>
                                    </template>
                                    <template slot="비밀번호 확인">
                                        <v-password-confirm-field :password="form.password" placeholder="동일한 비밀번호를 재입력하세요." />
                                    </template>
                                    <template slot="연락처">
                                        <v-row class="row--xs">
                                            <!-- <v-col cols="12">
                                                <v-phone-field v-model="phone" v-bind="{ ...attrs_input, loading }" :dense="false" :hasCertification="false"/>
                                            </v-col> -->
                                            <v-col cols="12">
                                                <v-row align="center" :class="{ 'row--xxs': true }">
                                                    <v-col>
                                                        <v-text-field v-model="phone1" v-bind="{ ...attrs_input, label, readonly: true, dense: false }" type="number" />
                                                    </v-col>
                                                    <v-col cols="auto">-</v-col>
                                                    <v-col>
                                                        <v-text-field v-model="phone2" v-bind="{ ...attrs_input, readonly: true, dense: false }" type="number" />
                                                    </v-col>
                                                    <v-col cols="auto">-</v-col>
                                                    <v-col>
                                                        <v-text-field v-model="phone3" v-bind="{ ...attrs_input, readonly: true, dense: false }" type="number" />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template slot="직급·직책">
                                        <v-row class="row--xxs">
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="form.position" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="직급을 입력해주세요." />
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="form.job" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="직책을 입력해주세요." />
                                            </v-col>
                                        </v-row>
                                    </template>
                                </tit-form-section>
                            </page-section-primary>

                            <page-section-primary titWrapAlign="text-left" titWrapSize="tit-wrap--xs" titSize="tit--sm" class="page-section--xs pb-0">
                                <v-row class="row--sm">
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_tertiary }" x-large class="w-100 min-w-sm-200px" @click="withdraw">회원탈퇴</v-btn>
                                    </v-col>
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary }" x-large class="w-100 min-w-sm-200px" @click="save">수정하기</v-btn>
                                    </v-col>
                                </v-row>
                            </page-section-primary>
                        </v-form>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { initUser, rules } from "@/assets/variables";
import { attrs_input, btn_primary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import MyinfoTabVerify from "@/components/client/mypage/myinfo/myinfo-tab-verify.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

const corporations = [
    {
        term: "사업자등록번호",
        type: "address",
        required: false,
    },
    {
        key: "company.businessLicense",
        term: "사업자등록증",
        type: "file",
        required: false,
        placeholder: "파일을 첨부하세요.",
        messages: "인증이 불가할시 사업자등록증을 첨부해주세요.",
    },
    {
        key: "company.name",
        term: "기업명",
        type: "text",
        required: false,
        placeholder: "기업명을 입력해주세요.",
    },
    {
        key: "company.ceo",
        term: "대표자 성명",
        type: "text",
        required: false,
        placeholder: "대표자 성명을 입력해주세요.",
    },
    {
        term: "주소",
        type: "address",
        required: false,
    },
].map((item) => ({ ...item, outlined: true }));

const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: false,
        placeholder: "아이디를 입력하세요.",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: false,
        placeholder: "비밀번호를 입력하세요.",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: false,
        placeholder: "동일한 비밀번호를 재입력하세요.",
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: false,
        hasCertification: false,
    },
    {
        key: "name",
        term: "담당자명",
        type: "text",
        required: false,
        placeholder: "담당자명을 입력해주세요.",
    },
    {
        key: "tel",
        term: "유선전화번호",
        type: "text",
        required: false,
        placeholder: "유선전화번호를 입력해주세요.",
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: false,
        placeholder: "이메일을 입력하세요.",
        rules: rules.email,
    },
    {
        term: "직급·직책",
        type: "text",
        required: false,
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        TitFormSection,
        VerticalFormTable,
        VAddressField,
        MyinfoTabVerify,
        VPasswordConfirmField,
        VPhoneField,
        PageSectionPrimary,
        TxtPrimary,
    },
    data: () => ({
        attrs_input,
        btn_primary,
        btn_tertiary,

        form: initUser(),
        corporationForm: initUser(),

        tab: "verify",

        phone: null,
        otp: null,

        items,
        corporations,
        isValid: false,

        loading: false,

        phone1: null,
        pnone2: null,
        phone3: null
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push("/login");
                    return;
                }

                this.form = initUser((await api.v1.me.get())?.user);
                let phone = this.form.phone.split("-")
                this.phone1 = phone[0]
                this.phone2 = phone[1]
                this.phone3 = phone[2]
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        validate() {
            try {
                if (this.form.password) {}
                return true;
            } catch (error) {
                return false;
            }
        },

        async save() {
            try {
                let { password, ...form } = this.form;
                if (password) form.password = CryptoAES.encrypt(password);

                const { user } = await api.v1.me.put(form);
                this.form = user;

                alert("수정되었습니다");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },
        async withdraw() {
            if (confirm("회원탈퇴시 보유하고 있는 포인트는 모두 소멸됩니다. 그래도 탈퇴하시겠습니까?")) {
                await api.v1.me.withdraw()
                alert("회원탈퇴 되었습니다")
                this.$store.dispatch("logout");
            }
        },
        async certify() {
            if (this.validate()) {
                let { phone } = this;
                await api.v1.users.certify.post({
                    type: "phone",
                    kind: "username",
                    phone,
                });

                alert("인증번호가 전송되었습니다");
            }
        },
        async recovery() {
            if (this.validate(true)) {
                let { phone, otp } = this;
                await api.v1.users.recovery.post({
                    type: "phone",
                    kind: "username",
                    phone,
                    otp,
                });

                alert("휴대전화번호로 아이디가 발송되었습니다");
            }
        },
    },
};
</script>
