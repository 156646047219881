<template>
    <v-tab-item :value="tab">
        <div class="mw-588px">
            <u-tit-wrap-subtitle titSize="tit--sm" class="mb-0 pb-12px pb-md-20px">
                <template #tit>비밀번호 입력</template>
                <template #subTit>개인정보 확인을 위해 비밀번호를 입력해 주세요.</template>
            </u-tit-wrap-subtitle>

            <tit-form-section v-model="form" v-bind="{ items }">
                <!-- <template slot="아이디">
                    <span class="font-size-14 primary--text">
                        {{ username }}
                    </span>
                </template> -->
            </tit-form-section>

            <div class="btn-wrap">
                <v-row class="row--sm">
                    <v-col cols="6" sm="auto">
                        <v-btn v-bind="{ ...btn_primary, loading }" x-large class="w-100 min-w-sm-200px" @click="verify">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { btn_primary, attrs_input } from "@/assets/variables";

import UTitWrapSubtitle from "@/components/publish/styles/typography/u-tit-wrap-subtitle.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

const items = [
    // {
    //     term: "아이디",
    // },
    {
        term: "비밀번호",
        type: "password",
        key: "password",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        UTitWrapSubtitle,
        TitFormSection,
        VerticalFormTable,
        PageSectionPrimary,
    },
    props: {
        tab: { type: String, default: "verify" },
    },
    data: () => ({
        btn_primary,
        attrs_input,

        form: {
            password: "",
        },

        items,
        loading: false,
    }),
    computed: {
        username() {
            return this.$store.state.user?.username;
        },
    },
    methods: {
        async verify() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password } = this.form;
                password = CryptoAES.encrypt(password);
                await api.v1.me.verify({ password });
                this.$emit("next");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
