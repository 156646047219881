var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-point",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 20 20"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M17.4,10.4c-0.9-0.4-2.2-0.6-3.5-0.6s-2.5,0.2-3.5,0.6c-1.1,0.4-1.7,1.1-1.7,1.8v4.4c0,0.8,0.6,1.4,1.7,1.8\n\t\tc0.9,0.4,2.2,0.6,3.5,0.6c1.3,0,2.5-0.2,3.5-0.6c1.1-0.4,1.7-1.1,1.7-1.8v-4.4C19.1,11.5,18.5,10.8,17.4,10.4z M18,16.7\n\t\tc0,0,0,0.2-0.2,0.3c-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.3-1.9,0.5-3.1,0.5s-2.3-0.2-3.1-0.5c-0.3-0.1-0.6-0.3-0.8-0.4\n\t\tc-0.2-0.2-0.2-0.3-0.2-0.3V16c0.4,0.2,1,0.5,1.6,0.6c1.5,0.4,3.4,0.4,4.9,0c0.6-0.2,1.2-0.4,1.6-0.6V16.7z M18,14.4\n\t\tc0,0,0,0.2-0.2,0.3c-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.3-1.9,0.5-3.1,0.5s-2.3-0.2-3.1-0.5c-0.3-0.1-0.6-0.3-0.8-0.4\n\t\tc-0.2-0.2-0.2-0.3-0.2-0.3v-0.7c0.4,0.2,1,0.5,1.6,0.6c1.5,0.3,3.4,0.3,4.9,0c0.6-0.2,1.2-0.4,1.6-0.6V14.4z M17.7,12.6\n\t\tc-0.2,0.2-0.5,0.3-0.8,0.4c-0.8,0.3-1.9,0.5-3.1,0.5c-1.2,0-2.3-0.2-3.1-0.5c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.2-0.2-0.3-0.2-0.3\n\t\ts0-0.2,0.2-0.3c0.2-0.2,0.5-0.3,0.8-0.4c0.8-0.3,1.9-0.5,3.1-0.5s2.3,0.2,3.1,0.5c0.3,0.1,0.6,0.3,0.8,0.4c0.2,0.2,0.2,0.3,0.2,0.3\n\t\tS18,12.4,17.7,12.6z"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.1,12.3c0.4,0,0.8,0,1.1-0.1c0.2,0,0.4-0.3,0.4-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.6,0-1,0\n\t\tc-2.7,0-4.2-0.9-4.2-1.4V9.2c0.9,0.6,2.5,0.9,4.2,0.9c2.5,0,5.1-0.8,5.1-2.3V3.3C11.2,1.8,8.6,1,6.1,1C3.6,1,1,1.8,1,3.3v11.1\n\t\tc0,1.5,2.6,2.3,5.1,2.3c0.4,0,0.8,0,1.1-0.1c0.2,0,0.4-0.3,0.4-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1\n\t\tc-0.3,0-0.6,0-1,0c-2.7,0-4.2-0.9-4.2-1.4v-0.8c0.9,0.6,2.5,0.9,4.2,0.9c0.4,0,0.8,0,1.1-0.1c0.2,0,0.4-0.3,0.4-0.5\n\t\tc0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.6,0-1,0c-2.7,0-4.2-0.9-4.2-1.4v-0.8C2.9,12,4.4,12.3,6.1,12.3z M6.1,2\n\t\tc2.7,0,4.2,0.9,4.2,1.4S8.9,4.7,6.1,4.7c-2.7,0-4.2-0.9-4.2-1.4S3.4,2,6.1,2z M1.9,4.7c0.9,0.6,2.5,0.9,4.2,0.9\n\t\tc1.7,0,3.3-0.4,4.2-0.9v0.8c0,0.5-1.4,1.4-4.2,1.4C3.4,6.9,1.9,6,1.9,5.6V4.7z M1.9,6.9c0.9,0.6,2.5,0.9,4.2,0.9\n\t\tc1.7,0,3.3-0.4,4.2-0.9v0.8c0,0.5-1.4,1.4-4.2,1.4c-2.7,0-4.2-0.9-4.2-1.4V6.9z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }