<template>
    <client-page>
        <mypage-layout title="포인트 충전내역">
            <v-card v-bind="{ ...card_secondary }">
                <div class="pa-16px pa-md-24px">
                    <v-row class="row--xs">
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">충전/사용 기간</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[0]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                    <!-- <v-select v-bind="{ ...attrs_input, ...$attrs }" placeholder="선택" class="white w-md-170px" /> -->
                                </v-col>
                                <v-col cols="auto">~</v-col>
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[1]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                    <!-- <v-select v-bind="{ ...attrs_input, ...$attrs }" placeholder="선택" class="white w-md-170px" /> -->
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">상태값</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.type" :items="typeItems" item-text="text" item-value="value" v-bind="{ ...attrs_input, ...$attrs }" placeholder="충전" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색조건</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.searchKey" :items="searchKeys" v-bind="{ ...attrs_input, ...$attrs }" placeholder="주문명" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" lg="">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색어</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col cols="">
                                    <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input, ...$attrs }" placeholder="검색어를 입력하세요" class="white" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary }" color="grey" class="v-btn--input-comfortable min-w-auto" @click="search()">검색</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <div class="pt-16px pt-md-20px pt-lg-24px">
                <v-data-table v-bind="{ headers, items, ...table_primary }" no-data-text="내역이 없습니다">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.amount`]="{ item }">
                        {{ item?.amount?.format() }} 원
                    </template>
                    <template #[`item.orderName`]="{ item }">
                        {{ item?.orderName ? item.orderName : "-" }}
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                        {{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss")}}
                    </template>
                    <template #[`item.type`]="{ item }">
                        <v-chip v-if="item.type == 'CHARGE'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">충전</v-chip>
                        <v-chip v-if="item.type == 'USE'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">사용</v-chip>
                    </template>
                </v-data-table>
            </div>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { attrs_input, btn_primary, chip_primary, chip_primary2, chip_primary3, card_secondary, table_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

const headers = [
    { width: "21%", align: "center", text: "주문/사용 번호", value: "tno" },
    { width: "24%", align: "center", text: "충전/사용 기간", value: "createdAt" },
    { width: "25%", align: "center", text: "주문명", value: "orderName" },
    { width: "15%", align: "center", text: "금액", value: "amount" },
    { width: "15%", align: "center", text: "상태값", value: "type" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        VDateField,
        TxtSecondary,
    },

    data() {
        return {
            attrs_input,
            btn_primary,
            chip_primary,
            chip_primary2,
            chip_primary3,
            card_secondary,
            table_primary,

            limit: 10,
            summary: { totalCount: 0 },
            filter: {
                type: null,
                searchKey: null,
                searchValue: null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            loading: false,

            headers,
            points: [],

            typeItems: [
                { text: "전체", value: null },
                { text: "사용", value: "USE" },
                { text: "충전", value: "CHARGE" },
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "주문/사용 번호", value: "tno" },
                { text: "주문명", value: "orderName" },
            ]
        }
    },
    computed: {
        items() {
            return [...this.points].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, points } = await api.v1.me.points.gets({
                    headers: { skip, limit },
                    params: { ...this.filter },
                });

                this.summary = summary;
                this.points = points;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
