var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$slots['tit'] ? _c('tit-wrap', {
    staticClass: "tit-wrap--underline",
    class: this.$slots['subTit'] || _vm.titAlign != undefined ? _vm.titAlign : 'text-left'
  }, [_c('tit-primary', {
    class: this.$slots['subTit'] ? 'd-sm-inline-flex align-center' : '',
    attrs: {
      "titSize": _vm.titSize
    }
  }, [_vm._t("tit"), this.$slots['subTit'] ? _c('v-divider', {
    staticClass: "h-10px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block",
    attrs: {
      "vertical": ""
    }
  }) : _vm._e(), this.$slots['subTit'] ? _c('txt-primary', {
    staticClass: "d-block d-sm-inline-block txt--dark pt-8px pt-sm-0",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._t("subTit")], 2) : _vm._e()], 2), this.$slots['txt'] ? _c('txt-primary', {
    staticClass: "txt--dark",
    attrs: {
      "txtSize": "txt--sm"
    }
  }, [_vm._t("txt")], 2) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }