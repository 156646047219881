var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column h-100 overflow-hidden",
    attrs: {
      "tile": "",
      "tag": "aside",
      "width": "100%"
    }
  }, [_c('aside-user-card'), _c('aside-navigation', _vm._b({}, 'aside-navigation', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "d-none d-lg-block mt-auto"
  }, [_c('v-divider', {
    staticClass: "mt-20px"
  }), _c('v-btn', {
    staticClass: "justify-start",
    staticStyle: {
      "height": "54px !important"
    },
    attrs: {
      "small": "",
      "text": "",
      "tile": "",
      "block": "",
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('u-icon', {
    staticClass: "mr-10px"
  }, [_vm._v("logout")]), _vm._v(" 로그아웃 ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }