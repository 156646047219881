<template>
    <client-page>
        <mypage-layout title="템플릿 관리">
            <v-card v-bind="{ ...card_secondary }">
                <div class="pa-16px pa-md-24px">
                    <v-row class="row--xs">
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">생성기간</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[0]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                </v-col>
                                <v-col cols="auto">~</v-col>
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[1]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">상태값</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.statusMessage" :items="statusMessageItems" v-bind="{ ...attrs_input, ...$attrs }" placeholder="미사용" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col> -->
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색조건</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.searchKey" :items="searchKeys" v-bind="{ ...attrs_input, ...$attrs }" placeholder="상품명" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" lg="">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색어</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col cols="">
                                    <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input, ...$attrs }" placeholder="검색어를 입력하세요" class="white" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary }" color="grey" class="v-btn--input-comfortable min-w-auto" @click="search()">검색</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
<!-- 
            <v-row class="mt-2 px-1">
                <v-col cols="auto" class="py-0"></v-col>
                <v-spacer />
                <v-col cols="auto" class="py-0">
                    <v-btn color="white" class="green--text px-2 ml-1">
                        <span @click="excel"><v-icon>mdi-microsoft-excel</v-icon>엑셀다운로드</span>
                    </v-btn>
                </v-col>
            </v-row> -->

            <div class="pt-16px pt-md-20px pt-lg-24px">
                <v-data-table v-bind="{ headers, items, ...table_primary }" no-data-text="내역이 없습니다">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.createdAt`]="{ item }">
                        {{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                    <template #[`item.faceamt`]="{ item }">
                        {{ item?.faceamt?.format() }}원
                    </template>
                    <template #[`item.statusMessage`]="{ value }">
                        <v-chip v-if="value == '사용'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">사용완료</v-chip>
                        <v-chip v-if="value == '발행'" v-bind="{ ...chip_primary2 }" x-small style="min-width: 64px">미사용</v-chip>
                        <v-chip v-if="value == '폐기'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">폐기</v-chip>
                    </template>
					<template #[`item.actions`]="{ item }">
						<v-row justify="end" class="row--xxs">
							<v-col cols="auto">
								<v-btn v-bind="{ ...btn_primary3 }" class="v-size--xx-small" @click="remove(item)">삭제하기</v-btn>
							</v-col>
							<v-col cols="auto">
								<v-btn v-bind="{ ...btn_primary }" class="v-size--xx-small" :to="`/mypage/template/${item._id}`">수정하기</v-btn>
							</v-col>
						</v-row>
                    </template>
                </v-data-table>
            </div>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, btn_primary, chip_primary, chip_primary2, chip_primary3, card_secondary, table_primary, btn_primary3 } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

const headers = [
    { width: "12%", align: "center", text: "템플릿명", value: "name" },
    { width: "16%", align: "center", text: "제목", value: "subject" },
    { width: "10%", align: "center", text: "고객센터", value: "center" },
    { width: "14%", align: "center", text: "생성일", value: "createdAt" },
    { width: "10%", align: "center", text: "", value: "actions" },
    // { width: "15%", align: "center", text: "상품명", value: "order.orderName" },
    // { width: "12%", align: "center", text: "액면가", value: "faceamt" },
    // { width: "10%", align: "center", text: "상태값", value: "statusMessage" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        VDateField,
        TxtSecondary,
    },

    data() {
        return {
            attrs_input,
            btn_primary,
            chip_primary,
            chip_primary2,
            chip_primary3,
            card_secondary,
            table_primary,
			btn_primary3,

            templates: [],

            limit: 10,
            summary: { totalCount: 0 },

            filter: {
                searchKey: null,
                searchValue: null,
                statusMessage: null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            loading: false,

            headers,

            statusMessageItems: [
                { text: "전체", value: null },
                { text: "사용완료", value: "사용" },
                { text: "폐기", value: "폐기" },
                { text: "미사용", value: "발행" }
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "템플릿명", value: "name" },
				{ text: "제목", value: "subject" }
            ]
        }
    },
    computed: {
        items() {
            return [...this.templates].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, templates } = await api.v1.me.templates.gets({
                    headers: { skip, limit },
                    params: { ...this.filter },
                });

                this.summary = summary;
                this.templates = templates;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

		async remove(template) {
            if (confirm("템플릿을 삭제하시겠습니까?")) {
                await api.v1.me.templates.delete({ _id: template._id });
                alert("템플릿이 삭제되었습니다");
				await this.init();
            }
        },

        async excel() {
            try {
                var { templates } = await api.v1.me.templates.gets({
                    params: { ...this.filter },
                });

                var rows = templates.map((purchase) => ({
                    주문번호: purchase?.purchaseNo,
                    발행일: this.$dayjs(purchase?.createdAt).format("YYYY-MM-DD HH:mm:ss"), 
                    수신인: purchase?.receiver?.name,
                    전화번호: purchase?.receiver?.phone,
                    상품명: purchase?.order?.orderName,
                    액면가: purchase?.faceamt?.format(),
                    상태값: purchase?.statusMessage,
                    만료일: this.$dayjs(purchase?.expiredAt).format("YYYY-MM-DD HH:mm:ss")
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "발송내역.xlsx");
            } catch(error) {
                alert(error.message)
            }
        },
    },
};
</script>
