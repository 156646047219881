<template>
    <client-page>
        <mypage-layout title="주문내역">
            <v-card v-bind="{ ...card_secondary }">
                <div class="pa-16px pa-md-24px">
                    <v-row class="row--xs">
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">구매기간</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[0]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                    <!-- <v-select v-bind="{ ...attrs_input, ...$attrs }" placeholder="선택" class="white w-md-170px" /> -->
                                </v-col>
                                <v-col cols="auto">~</v-col>
                                <v-col>
                                    <v-date-field v-model="filter.createdAt[1]" :textFieldAttrs="{ ...attrs_input, className: 'white w-md-170px' }" hideDetails="auto" placeholder="선택"></v-date-field>
                                    <!-- <v-select v-bind="{ ...attrs_input, ...$attrs }" placeholder="선택" class="white w-md-170px" /> -->
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">권종</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.faceamt" :items="faceamtItems" v-bind="{ ...attrs_input, ...$attrs }" placeholder="미사용" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색조건</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <v-select v-model="filter.searchKey" :items="searchKeys" v-bind="{ ...attrs_input, ...$attrs }" placeholder="상품명" class="white w-md-120px" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" lg="">
                            <div class="pb-8px pb-md-12px">
                                <txt-secondary class="txt--dark">검색어</txt-secondary>
                            </div>
                            <v-row align="center" class="row--xxs">
                                <v-col cols="">
                                    <v-text-field v-model="filter.searchValue" v-bind="{ ...attrs_input, ...$attrs }" placeholder="검색어를 입력하세요" class="white" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary }" color="grey" class="v-btn--input-comfortable min-w-auto" @click="search()">검색</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-card>

            <v-row class="mt-2 px-1">
                <v-col cols="auto" class="py-0"></v-col>
                <v-spacer />
                <v-col cols="auto" class="py-0">
                    <v-btn color="white" class="green--text px-2 ml-1">
                        <span @click="excel"><v-icon>mdi-microsoft-excel</v-icon>엑셀다운로드</span>
                    </v-btn>
                </v-col>
            </v-row>

            <div class="pt-16px pt-md-20px pt-lg-24px">
                <v-data-table v-bind="{ headers: orderHeaders, items, ...table_primary }" no-data-text="내역이 없습니다" class="v-data-table--mobile v-data-table--board table-fixed" @click:row="(any, { item }) => showOrder(item)">
                    <template #[`item.faceamt`]="{ item }">
                        <div>{{ item.faceamt.format() }}원</div>
                    </template>
                    <template #[`item.quantity`]="{ item }">
                        <div>{{ item.quantity.format() }}개</div>
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                        <div>{{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
                    </template>
                    <template #[`item.action`]="{ item }">
                        <v-btn small color="primary" @click="showOrder(item)">보기</v-btn>
                    </template>
                    <!-- <template #[`item.purchaseNo`]="{ item }">
                        <div>{{ item.purchaseNo }}</div>
                        <div>{{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
                    </template>
                    <template #[`item.receiver.name`]="{ item }">
                        <div>{{ item.receiver.name }}</div>
                        <div>{{ item.receiver.phone }}</div>
                    </template> -->
                    <!-- <template #[`item.createdAt`]="{ item }">
                        {{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                    </template> -->
                    <!-- <template #[`item.faceamt`]="{ item }">
                        {{ item?.faceamt?.format() }}원
                    </template>
                    <template #[`item.statusMessage`]="{ value }">
                        <v-chip v-if="value == '사용'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">사용완료</v-chip>
                        <v-chip v-if="value == '발행'" v-bind="{ ...chip_primary2 }" x-small style="min-width: 64px">미사용</v-chip>
                        <v-chip v-if="value == '폐기'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">폐기</v-chip>
                    </template>
                    <template #[`item.pin.sendStatus`]="{ value }">
                        <v-chip v-if="value == '발송'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">발송</v-chip>
                        <v-chip v-if="value == '미발송'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">미발송</v-chip>
                    </template>
                    <template #[`item.reserveDate`]="{ item }">
                        {{ $dayjs(item?.reserveDate).format("YYYY-MM-DD HH:mm:ss") || "-" }}
                    </template>
                    <template #[`item.expiredAt`]="{ item }">
                        {{ $dayjs(item?.expiredAt).format("YYYY-MM-DD") }}
                    </template> -->
                </v-data-table>
            </div>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, btn_primary, chip_primary, chip_primary2, chip_primary3, card_secondary, table_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

const headers = [
    { width: "10%", align: "center", text: "주문번호/발행일", value: "purchaseNo" },
    // { width: "16%", align: "center", text: "발행일", value: "createdAt" },
    { width: "15%", align: "center", text: "수신인/전화번호", value: "receiver.name" },
    // { width: "14%", align: "center", text: "전화번호", value: "receiver.phone" },
    { width: "15%", align: "center", text: "상품명", value: "orderName" },
    { width: "12%", align: "center", text: "액면가", value: "faceamt" },
    { width: "10%", align: "center", text: "상태값", value: "statusMessage" },
    { width: "10%", align: "center", text: "발송 상태값", value: "pin.sendStatus" },
    { width: "16%", align: "center", text: "발송시간", value: "reserveDate" },
    { width: "15%", align: "center", text: "만료일", value: "expiredAt" },
];

const orderHeaders = [
    { width: "10%", align: "center", text: "주문번호", value: "orderNo" },
    { width: "15%", align: "center", text: "주문명", value: "orderName" },
    { width: "15%", align: "center", text: "권종", value: "faceamt" },
    { width: "15%", align: "center", text: "수량", value: "quantity" },
    { width: "15%", align: "center", text: "구매일", value: "createdAt" },
    { width: "10%", align: "center", text: "상세보기", value: "action" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        VDateField,
        TxtSecondary,
    },

    data() {
        return {
            attrs_input,
            btn_primary,
            chip_primary,
            chip_primary2,
            chip_primary3,
            card_secondary,
            table_primary,

            purchases: [],
            orders: [],

            limit: 10,
            summary: { totalCount: 0 },

            filter: {
                searchKey: null,
                searchValue: null,
                faceamt: null,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            loading: false,

            headers,
            orderHeaders,

            statusMessageItems: [
                { text: "전체", value: null },
                { text: "사용완료", value: "사용" },
                { text: "폐기", value: "폐기" },
                { text: "미사용", value: "발행" }
            ],

            faceamtItems: [
                { text: "전체", value: null },
                { text: "5,000원", value: 5000 },
                { text: "10,000원", value: 10000 },
                { text: "30,000원", value: 30000 },
                { text: "50,000원", value: 50000 },
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "주문번호", value: "orderNo" },
                { text: "주문명", value: "orderName" },
            ]
        }
    },
    computed: {
        items() {
            return [...this.orders].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                // var { summary, purchases } = await api.v1.me.gshop.purchases.gets({
                //     headers: { skip, limit },
                //     params: { ...this.filter },
                // });
                let { summary, orders } = await api.v1.me.gshop.orders.gets({
                    headers: { skip, limit },
                    params: { ...this.filter },
                })
                this.summary = summary;
                this.orders = orders
                // this.purchases = purchases;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            try {
                var { orders } = await api.v1.me.gshop.orders.gets({
                    params: { ...this.filter },
                });

                var rows = orders.map((order) => ({
                    주문번호: order?.orderNo,
                    주문명: order?.orderName, 
                    권종: `${order?.faceamt?.format()}원`,
                    수량:`${order?.quantity?.format()}개`,
                    구매일: this.$dayjs(order?.createdAt).format("YYYY-MM-DD HH:mm:ss")
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "주문내역.xlsx");
            } catch(error) {
                alert(error.message)
            }
        },

        showOrder(item) {
            this.$router.push(`/mypage/orders/${item._id}`);
        },
    },
};
</script>
