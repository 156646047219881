var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('div', {
    staticClass: "mw-588px"
  }, [_c('u-tit-wrap-subtitle', {
    staticClass: "mb-0 pb-12px pb-md-20px",
    attrs: {
      "titSize": "tit--sm"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("비밀번호 입력")];
      },
      proxy: true
    }, {
      key: "subTit",
      fn: function () {
        return [_vm._v("개인정보 확인을 위해 비밀번호를 입력해 주세요.")];
      },
      proxy: true
    }])
  }), _c('tit-form-section', _vm._b({
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'tit-form-section', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 min-w-sm-200px",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": _vm.verify
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("확인")])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }