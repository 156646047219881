<template>
    <client-page>
        <mypage-layout title="템플릿 수정">
			<v-row class="row--xl">
				<v-col cols="12" md="auto" order-md="2">
					<v-card v-bind="{ ...card_secondary }" min-width="282px">
						<div class="pa-16px pa-md-24px">
							<tit-wrap-primary titWrapSize="pb-12px pb-md-16px" titWrapAlign="text-left" titSize="tit--xs">미리보기</tit-wrap-primary>
							<v-card>
								<v-textarea :value="preview" v-bind="{ ...attrs_input }" readonly no-resize rows="9"/>
							</v-card>
						</div>
					</v-card>
					<div class="mt-8px mt-md-12px text-right">
						<v-chip small color="primary lighten-5"><span class="primary--text">단문 {{ preview.length + 590}}/1000</span></v-chip>
					</div>
				</v-col>
				<v-col cols="12" md="" order-md="1">
					<v-form>
						<tit-form-section :items="templateInput">
							<template slot="템플릿명">
								<v-row align="center" class="row--xxs">
									<v-col cols="12" sm="">
										<v-text-field v-model="template.name" v-bind="{ ...attrs_input }" placeholder="템플릿을 입력해주세요"/>
									</v-col>
								</v-row>
							</template>
							<template slot="제목">
								<v-text-field v-model="template.subject" v-bind="{ ...attrs_input }" placeholder="제목을 입력해주세요" />
							</template>
							<template slot="컨텐츠">
								<div>
									<v-textarea v-model="template.content" v-bind="{ ...attrs_input }" :maxlength="282" :counter="282" no-resize rows="9" @input="validateContent"/>
								</div>
							</template>
							<template slot="고객센터">
								<div>
									<v-text-field v-model="template.center" v-bind="{ ...attrs_input }" placeholder="고객센터" :maxlength="14" :counter="14"/>
								</div>
								<div class="pt-8px pt-md-12px">
									{{ `* 사용자의 이름을 변수로 사용하기 위해서 {이름}의 형태로 컨텐츠 영역에 입력해주시면, 각 문자의 이름이 자동으로 입력됩니다. \n변수 사용 후 실 데이터에 이름 정보가 없는 경우 "고객"으로 표기됩니다.` }}
								</div>
								<div class="pt-8px pt-md-12px">
									<v-textarea :value="preview" v-bind="{ ...attrs_controls_common }" readonly no-resize rows="9" />
								</div>
							</template>
						</tit-form-section>
					</v-form>
				</v-col>
			</v-row>
            
            <div class="btn-wrap">
				<v-row justify="center" class="row--xs">
					<v-col cols="auto">
						<v-btn v-bind="{ ...btn_secondary }" x-large class="v-btn--width-fixed" @click="$router.push('/mypage/template')">취소하기</v-btn>
					</v-col>
					<v-col cols="auto">
						<v-btn v-bind="{ ...btn_primary }" x-large class="v-btn--width-fixed" @click="save">수정하기</v-btn>
					</v-col>
				</v-row>
			</div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

import { attrs_input, attrs_controls_common, attrs_select_common, btn_primary, btn_primary2, btn_primary3, btn_secondary, btn_tertiary, card_primary, card_secondary, table_primary } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        MypageLayout,
        TitFormSection,
        TitWrapPrimary
    },
    data() {
        return {
			attrs_input,
			attrs_controls_common,
			attrs_select_common,
			btn_primary,
			btn_primary2,
			btn_primary3,
			btn_secondary,
			btn_tertiary,
			card_primary,
			card_secondary,
			table_primary,

            template: null,
            templateInput: [
				{ term: "템플릿명", outlined: true},
				{ term: "제목", outlined: true },
				{ term: "컨텐츠", outlined: true },
				{ term: "고객센터", outlined: true },
			],
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { template } = await api.v1.me.templates.get({ _id: this.$route.params._template })
            this.template = template
        },
        async save() {
            if (confirm("수정사항을 저장하시겠습니까 ?")) {
                await api.v1.me.templates.put(this.template)
                alert("저장되었습니다")
                this.$router.push("/mypage/template")
            }
        },
        validateContent() {
			if (this.preview.length + 590 > 1000) {
				this.template.content = this.template.content.slice(0, -1);
			}
		}
    },
    computed: {
		preview() {
            let preview = `대한문고 상품권 핀번호 안내\n\n상품명 : 대한문고 상품권\n권종 : 6,000원 권\n유효기간 : 2023-12-12\n\n핀번호\n1234-1234-1234-1234\n\n▶충전하러가기\n충전 URL\n\n고객센터: ${this?.template?.center || ""}`;

            let previewItems = [];

            if (previewItems.length) preview += previewItems.join("\n");
            preview = "\n" + preview.trim();

            if (this.template?.content) preview = `${this.template?.content?.trim?.()}\n` + preview

            return preview.trim();
        }
	},
    watch: {
		preview() {
			if (this.preview.length + 590 > 1000) {
				alert("1000자 이상 발송할 수 없습니다")
			}
		}
	}
};
</script>
