<template>
    <div>
        <v-simple-table class="v-data-table--line table-fixed">
            <tbody>
                <tr>
                    <th>
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="">
                                <v-text-field v-model="question.subject" v-bind="{ ...attrs_input, loading }" placeholder="제목을 입력해주세요." />
                            </v-col>
                        </v-row>
                    </th>
                </tr>
                <tr>
                    <td>
                        <v-select v-model="question.type" :items="typesItems" v-bind="{ ...attrs_input, loading }"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="">
                                <v-text-field v-model="email1" v-bind="{ ...attrs_input, loading }" placeholder="이메일을 입력하세요." />
                            </v-col>
                            @
                            <v-col cols="12" sm="">
                                <v-combobox v-model="email2" :items="emailItems" v-bind="{ ...attrs_input, loading }" placeholder="직접입력"/>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
                <tr>
                    <td>
                        <v-textarea v-model="question.content" v-bind="{ ...attrs_input, loading }" placeholder="내용을 입력해주세요."></v-textarea>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="btn-wrap btn-wrap--sm">
            <v-row class="row--xs">
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_secondary }" @click="$router.go(-1)">취소</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn v-bind="{ ...btn_primary }" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";
import { attrs_input, btn_primary, btn_secondary } from "@/assets/variables";

export default {
    components: {
        VEmailField
    },
    props: {
        code: { type: String },
    },
    data: () => ({
        attrs_input,
        btn_primary,
        btn_secondary,

        question: {
            type: null,
            subject: null,
            content: null,
            email: null
        },

        email1: null,
        email2: null,

        typesItems: [
            { text: "문의 유형", value: null },
            { text: "발송 문의", value: "SEND" },
            { text: "결제 문의", value: "PAY" },
            { text: "취소/환불", value: "CANCEL" },
            { text: "기타", value: "ETC" },
        ],

        emailItems: ["naver.com", "hanmail.net", "google.com"],

        loading: false,
    }),
    async mounted() {
        // await this.init()
    },
    methods: {
        async save() {
            if (this.validate()) {
                await api.v1.me.center.questions.post({ ...this.question })
                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            }
        },
        validate() {
            try {
                if (!this.question.subject) throw new Error("제목을 입력해주세요");
                if (!this.question.type) throw new Error("문의 유형을 선택해주세요");
                if (!this.question.email) throw new Error("메일을 입력해주세요");
                if (!this.question.content) throw new Error("내용을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message)
                return false;
            }
        },
    },
    watch: {
        email1() {
            this.email1 && this.email2 ? this.question.email = `${this.email1}@${this.email2}`: ""
        },
        email2() {
            this.email1 && this.email2 ? this.question.email = `${this.email1}@${this.email2}`: ""
        }
    }
};
</script>
