<template>
    <v-card tile tag="aside" width="100%" class="d-flex flex-column h-100 overflow-hidden">
        <aside-user-card />
        <aside-navigation v-bind="{ items }" />
        <div class="d-none d-lg-block mt-auto">
            <v-divider class="mt-20px" />
            <v-btn small text tile block color="grey lighten-1" class="justify-start" style="height: 54px !important" @click="logout">
                <u-icon class="mr-10px">logout</u-icon>
                로그아웃
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import AsideUserCard from "@/components/client/dumb/aside-user-card.vue";
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

const items = [
    {
        icon: "icon-point",
        text: "포인트충전 내역",
        to: { path: "/mypage/point-charging" },
    },
    {
        icon: "icon-send",
        text: "주문내역",
        to: { path: "/mypage/orders" },
    },
    {
        icon: "icon-info",
        text: "템플릿 관리",
        to: { path: "/mypage/template" },
    },
    {
        icon: "icon-info",
        text: "개인정보 관리",
        to: { path: "/mypage/myinfo" },
    },
    {
        icon: "icon-question",
        text: "1:1 문의하기",
        to: { path: "/mypage/questions" },
    }
];

export default {
    components: {
        AsideUserCard,
        AsideNavigation,
        UIcon,
    },
    data: () => ({
        items,
    }),
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
