var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "mb-6px mb-md-10px"
  }, 'v-card', Object.assign({}, _vm.card_primary), false), [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-20 font-weight-bold line-height-1"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " 님")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }