<template>
    <v-container v-bind="$attrs" class="container--lg">
        <v-row class="mypage-layout-row">
            <v-col v-if="accessToken" class="mypage-layout-row__aside">
                <div class="mypage-layout-row__aside-inner">
                    <!-- <promoter-aside v-if="aside == 'promoter'" /> -->
                    <mypage-aside />
                </div>
            </v-col>
            <v-col :md="accessToken ? '' : undefined" class="mypage-layout-row__contents">
                <div class="pb-20px pb-md-40px">
                    <v-row align="center">
                        <v-col cols="12" md="">
                            <u-tit-wrap-underline v-bind="$attrs" class="mb-0">
                                <template #tit>{{ title }}</template>
                                <template v-if="subTitle" #subTit>{{ subTitle }}</template>
                            </u-tit-wrap-underline>
                        </v-col>
                        <v-col v-if="this.$slots['titleRight']" cols="12" md="auto" class="text-right">
                            <slot name="titleRight" />
                        </v-col>
                    </v-row>
                </div>

                <slot />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MypageAside from "@/components/client/mypage/aside/mypage-aside.vue";
import PromoterAside from "@/components/client/promoter/aside/promoter-aside.vue";
import UTitWrapUnderline from "@/components/publish/styles/typography/u-tit-wrap-underline.vue";
import { mapState } from "vuex";
export default {
    props: {
        title: { type: String },
        subTitle: { type: String },
        titleSpacingLg: { type: Boolean },
        titleRight: { type: Boolean },
        aside: { type: String, default: "" },
    },
    components: {
        MypageAside,
        PromoterAside,
        UTitWrapUnderline,
    },
    computed: {
        ...mapState(["accessToken"]),
    },
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mypage-layout-row {
    --grid-gutter-mypage: 10px;
    @media (min-width: 1400px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1600px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1700px) {
        --grid-gutter-mypage: 20px;
    }
    @media (min-width: 1800px) {
        --grid-gutter-mypage: 30px;
    }
    margin: calc(var(--grid-gutter-mypage) * -1) !important;
    > [class*="col"] {
        padding: var(--grid-gutter-mypage) !important;
    }

    &__aside,
    &__contents {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &__aside-inner {
        width: 100%;
        margin-bottom: 16px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .mypage-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: 240px;
            height: 100%;
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
@media (min-width: 1200px) {
    // .mypage-layout-row {
    //     &__aside,
    //     &__contents {
    //         flex: 0 0 100%;
    //         max-width: 100%;
    //     }
    //     &__aside-inner {
    //         width: 100%;
    //         margin-bottom: 20px;
    //     }
    // }
}
@media (min-width: 1400px) {
    .mypage-layout-row {
        &__aside-inner {
            width: 306px;
        }
    }
    // .mypage-layout-row {
    //     &__aside {
    //         flex: 0 0 auto;
    //         width: auto;
    //         max-width: 100%;
    //     }
    //     &__aside-inner {
    //         width: 240px;
    //         margin-bottom: 0;
    //     }
    //     &__contents {
    //         flex-basis: 0;
    //         flex-grow: 1;
    //         max-width: 100%;
    //     }
    // }
}
</style>
