<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" class="icon-svg icon-question">
        <path
            d="M6.2,14.1v-0.2h0l0-4.9c0-0.2-0.1-0.4-0.2-0.5C6,8.4,5.8,8.3,5.6,8.3H5.4V7.6c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7
	v0.7h-0.1c-0.2,0-0.4,0.1-0.5,0.3C14,8.7,14,8.9,14,9.1l0,5c0,0.3,0.2,0.6,0.5,0.7c-0.5,0.4-1.1,0.7-1.8,0.8
	c-0.4-1.1-1.5-1.8-2.7-1.6c-1.1,0.2-1.8,1.2-1.8,2.3c0,1.3,1.1,2.3,2.3,2.3c0.6,0,1.2-0.3,1.7-0.7l0,0v0c0.2-0.2,0.4-0.5,0.5-0.8
	c1.3-0.1,2.4-0.7,3.2-1.7l0.3-0.5c0.4-0.1,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.2v-2.6c0-0.5-0.2-1.1-0.5-1.5
	c-0.3-0.4-0.7-0.7-1.1-0.7V7.6c0-3.4-2.7-6.1-6.1-6.1C6.8,1.4,4,4.2,4,7.6v0.7c-1,0-1.8,0.9-1.8,2v2.5c0,1.1,0.8,1.9,1.9,2l1.5,0
	c0.3,0,0.6-0.3,0.6-0.6L6.2,14.1z M4.8,13.4H4.1c-0.3,0-0.5-0.2-0.5-0.5v-2.6c0-0.3,0.2-0.6,0.5-0.6h0.8V13.4z M11.2,16.8L11.2,16.8
	c-0.2,0.2-0.4,0.3-0.7,0.3c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C11.4,16.5,11.3,16.7,11.2,16.8z
	 M16.4,13c0,0.2-0.1,0.2-0.1,0.3c-0.1,0.1-0.3,0.1-0.3,0.1h-0.6V9.7h0.6c0.1,0,0.2,0.1,0.3,0.2c0.1,0.2,0.2,0.4,0.2,0.6V13z" />
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-question {
    width: 20px;
}
</style>
