<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" class="icon-svg icon-info">
        <g>
            <path
                d="M18.5,10.1c-0.3-0.3-0.7-0.4-1.1-0.3c-0.3,0.1-0.6,0.2-0.8,0.5l-6.2,6.2c-0.1,0.1-0.2,0.2-0.2,0.3l-0.4,2.5
		c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2h0c0,0,0.1,0,0.1,0l2.5-0.4c0.1,0,0.2-0.1,0.3-0.2l6.2-6.2c0.3-0.3,0.4-0.7,0.4-1v0
		c0-0.4-0.1-0.7-0.4-1L18.5,10.1z M18.7,12.3l-6,6l-1.4,0.3l0.3-1.4l6-6c0.1-0.1,0.1-0.1,0.2-0.1l1,1C18.8,12.1,18.8,12.2,18.7,12.3
		z" />
            <path
                d="M2.3,17.5h6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-6c-0.6,0-1-0.5-1-1V2.3c0-0.6,0.5-1,1-1h9.6c0.6,0,1,0.5,1,1
		v8.4c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V2.3c0-1.3-1-2.3-2.3-2.3H2.3C1,0,0,1,0,2.3v12.9C0,16.5,1,17.5,2.3,17.5z" />
            <path d="M11,6.7H3.1c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6H11c0.3,0,0.6-0.3,0.6-0.6C11.7,6.9,11.4,6.7,11,6.7z" />
            <path d="M7.7,10H3.1c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.6c0.3,0,0.6-0.3,0.6-0.6C8.3,10.3,8.1,10,7.7,10z" />
            <path d="M3.1,4.6h3.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6H3.1C2.8,3.3,2.5,3.6,2.5,4C2.5,4.3,2.8,4.6,3.1,4.6z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-info {
    width: 20px;
}
</style>
