<template>
    <client-page>
        <mypage-layout v-if="loading" title="주문내역">
            <div class="pt-16px pt-md-20px pt-lg-24px">
                <v-data-table :items="[order]" :headers="orderHeaders" v-bind="{ ...table_primary }" no-data-text="내역이 없습니다">
                    <template #[`item.faceamt`]="{ item }">
                        <div>{{ item.faceamt.format() }}원</div>
                    </template>
                    <template #[`item.quantity`]="{ item }">
                        <div>{{ item.quantity.format() }}개</div>
                    </template>
                    <template #[`item.createdAt`]="{ item }">
                        <div>{{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
                    </template>
                </v-data-table>
            </div>

            <v-row class="mt-2 px-1">
                <v-col cols="auto" class="py-0"></v-col>
                <v-spacer />
                <v-col cols="auto" class="py-0">
                    <v-btn color="white" class="green--text px-2 ml-1">
                        <span @click="excel"><v-icon>mdi-microsoft-excel</v-icon>엑셀다운로드</span>
                    </v-btn>
                </v-col>
            </v-row>

            <div class="pt-16px pt-md-20px pt-lg-24px">
                <v-data-table :items="items" :headers="purchaseHeaders" v-bind="{ ...table_primary }" no-data-text="내역이 없습니다">
                    <template #[`item.purchaseNo`]="{ item }">
                        <div>{{ item.purchaseNo }}</div>
                        <div>{{ $dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</div>
                    </template>
                    <template #[`item.receiver.name`]="{ item }">
                        <div>{{ item.receiver.name }}</div>
                        <div>{{ item.receiver.phone }}</div>
                    </template>
                    <template #[`item.faceamt`]="{ item }">
                        {{ item?.faceamt?.format() }}원
                    </template>
                    <template #[`item.statusMessage`]="{ value }">
                        <v-chip v-if="value == '사용'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">사용완료</v-chip>
                        <v-chip v-if="value == '발행'" v-bind="{ ...chip_primary2 }" x-small style="min-width: 64px">미사용</v-chip>
                        <v-chip v-if="value == '폐기'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">폐기</v-chip>
                    </template>
                    <template #[`item.pin.sendStatus`]="{ value }">
                        <v-chip v-if="value == '발송'" v-bind="{ ...chip_primary }" x-small style="min-width: 64px">발송</v-chip>
                        <v-chip v-if="value == '미발송'" v-bind="{ ...chip_primary3 }" x-small style="min-width: 64px">미발송</v-chip>
                    </template>
                    <template #[`item.reserveDate`]="{ item }">
                        {{ $dayjs(item?.reserveDate).format("YYYY-MM-DD HH:mm:ss") || "-" }}
                    </template>
                    <template #[`item.expiredAt`]="{ item }">
                        {{ $dayjs(item?.expiredAt).format("YYYY-MM-DD") }}
                    </template>
                </v-data-table>

                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </div>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, btn_primary, chip_primary, chip_primary2, chip_primary3, card_secondary, table_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

const purchaseHeaders = [
    { width: "10%", align: "center", text: "구매번호/구매일", value: "purchaseNo" },
    // { width: "16%", align: "center", text: "발행일", value: "createdAt" },
    { width: "15%", align: "center", text: "수신인/전화번호", value: "receiver.name" },
    // { width: "14%", align: "center", text: "전화번호", value: "receiver.phone" },
    { width: "12%", align: "center", text: "액면가", value: "faceamt" },
    { width: "10%", align: "center", text: "사용여부", value: "statusMessage" },
    { width: "10%", align: "center", text: "발송 상태", value: "pin.sendStatus" },
    { width: "16%", align: "center", text: "발송시간", value: "reserveDate" },
    { width: "15%", align: "center", text: "만료일", value: "expiredAt" },
];

const orderHeaders = [
    { width: "10%", align: "center", text: "주문번호", value: "orderNo" },
    { width: "15%", align: "center", text: "주문명", value: "orderName" },
    { width: "15%", align: "center", text: "권종", value: "faceamt" },
    { width: "15%", align: "center", text: "수량", value: "quantity" },
    { width: "15%", align: "center", text: "구매일", value: "createdAt" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        VDateField,
        TxtSecondary,
    },

    data() {
        return {
            attrs_input,
            btn_primary,
            chip_primary,
            chip_primary2,
            chip_primary3,
            card_secondary,
            table_primary,

            loading: true,
            order: null,
            purchases: [],

            purchaseHeaders,
            orderHeaders,

            limit: 10,
            summary: { totalCount: 0 },
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            this.loading = false;
            try {
                let { order } = await api.v1.me.gshop.orders.get({ _id: this.$route.params._order });
                this.order = order;
                await this.search();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = true;
            }
        },
        async search() {
            let { skip, limit } = this;
            let { summary, purchases } = await api.v1.me.gshop.purchases.gets({ 
                headers: { skip, limit },
                params: { _order: this.order._id }
            });
            this.summary = summary;
            this.purchases = purchases;
        },
        async excel() {
            try {
                var { purchases } = await api.v1.me.gshop.purchases.gets({ 
                    params: { _order: this.order._id }
                });

                var rows = purchases.map((purchase) => ({
                    주문번호: purchase?.purchaseNo,
                    구매일: this.$dayjs(purchase?.createdAt).format("YYYY-MM-DD HH:mm:ss"), 
                    수신인: purchase?.receiver?.name,
                    전화번호: purchase?.receiver?.phone,
                    액면가: purchase?.faceamt?.format(),
                    사용여부: purchase?.statusMessage,
                    발송상태: purchase?.pin?.sendStatus,
                    만료일: this.$dayjs(purchase?.expiredAt).format("YYYY-MM-DD HH:mm:ss")
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "구매내역.xlsx");
            } catch(error) {
                alert(error.message)
            }
        },
    },
    computed: {
        items() {
            return [...this.purchases].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    }
};
</script>
