<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" class="icon-svg icon-send">
        <g>
            <path d="M6,5.9h4.7c0.3,0,0.6-0.3,0.6-0.6S11,4.7,10.7,4.7H6c-0.3,0-0.6,0.3-0.6,0.6S5.7,5.9,6,5.9z" />
            <path d="M13,5.9h0.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H13c-0.3,0-0.6,0.3-0.6,0.6S12.7,5.9,13,5.9z" />
            <path d="M6,8.2h4.7c0.3,0,0.6-0.3,0.6-0.6S11,7,10.7,7H6C5.7,7,5.4,7.3,5.4,7.6S5.7,8.2,6,8.2z" />
            <path d="M6,3.5h4.7c0.3,0,0.6-0.3,0.6-0.6S11,2.3,10.7,2.3H6c-0.3,0-0.6,0.3-0.6,0.6S5.7,3.5,6,3.5z" />
            <path d="M13,3.5h0.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H13c-0.3,0-0.6,0.3-0.6,0.6S12.7,3.5,13,3.5z" />
            <path
                d="M18.5,8.4c-0.5-0.5-1.1-0.9-1.8-1.1V2.1c0-1.2-0.9-2.1-2.1-2.1H2.1c0,0,0,0,0,0c0,0,0,0,0,0C0.9,0,0,0.9,0,2.1v4.6
		C0,7,0.3,7.3,0.6,7.3H3v12.1c0,0.2,0.1,0.4,0.3,0.5C3.5,20,3.7,20,3.8,20l1.4-0.5L6.6,20c0.1,0,0.3,0,0.4,0l1.4-0.5L9.7,20
		c0.1,0,0.3,0,0.4,0l1.4-0.5l1.4,0.5c0.1,0,0.3,0,0.4,0l1.4-0.5l1.4,0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1
		c0.2-0.1,0.3-0.3,0.3-0.5v-2.7c0.7-0.3,1.3-0.6,1.8-1.1c1-1,1.5-2.2,1.5-3.6v0C20,10.7,19.5,9.4,18.5,8.4z M3,6.1H1.2v-4
		c0-0.5,0.4-0.9,0.9-0.9C2.6,1.2,3,1.6,3,2.1V6.1z M15.5,18.6l-0.8-0.3c-0.1,0-0.3,0-0.4,0L13,18.8l-1.4-0.5c-0.1,0-0.3,0-0.4,0
		l-1.4,0.5l-1.4-0.5c-0.1,0-0.3,0-0.4,0l-1.4,0.5l-1.4-0.5c-0.1,0-0.3,0-0.4,0l-0.8,0.3V2.1c0,0,0,0,0-0.1c0-0.3-0.1-0.6-0.2-0.8
		h10.6c0.5,0,0.9,0.4,0.9,0.9V7C15.3,7,15.1,7,14.9,7c-1.4,0-2.6,0.5-3.6,1.5c-0.3,0.3-0.5,0.6-0.7,0.9H6c-0.3,0-0.6,0.3-0.6,0.6
		s0.3,0.6,0.6,0.6h4.1c-0.1,0.4-0.2,0.8-0.2,1.2H6c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4c0.1,0.4,0.2,0.8,0.4,1.2H6
		c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.7c0.1,0,0.2,0,0.3-0.1c0.1,0.2,0.2,0.3,0.4,0.4c1,1,2.2,1.5,3.6,1.5
		c0.2,0,0.4,0,0.6-0.1V18.6z M17.7,14.8c-0.7,0.7-1.7,1.1-2.7,1.1c-1,0-2-0.4-2.7-1.1C11.5,14,11.1,13,11.1,12c0-1,0.4-2,1.1-2.7
		c0.7-0.7,1.7-1.1,2.7-1.1c1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.7,1.1,2.7C18.8,13,18.4,14,17.7,14.8z" />
            <path
                d="M15.8,11.8c0,0-0.1-0.1-0.1-0.1l-0.9-0.5c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.3,0.3-0.3h0h0.7c0.3,0,0.6-0.3,0.6-0.6
		c0-0.3-0.3-0.6-0.6-0.6h-0.1c0-0.3-0.3-0.5-0.6-0.5c-0.3,0-0.6,0.3-0.6,0.6v0c-0.5,0.2-0.9,0.7-0.9,1.3c0,0.5,0.2,0.9,0.6,1.2
		c0,0,0.1,0.1,0.1,0.1l0.9,0.5c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.3,0.3h0h0h-0.7c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6
		h0.1c0,0.3,0.3,0.5,0.6,0.5c0.3,0,0.6-0.3,0.6-0.6v0c0.5-0.2,0.9-0.7,0.9-1.3C16.4,12.5,16.2,12.1,15.8,11.8z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-send {
    width: 20px;
}
</style>
