var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "템플릿 수정"
    }
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto",
      "order-md": "2"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "min-width": "282px"
    }
  }, 'v-card', Object.assign({}, _vm.card_secondary), false), [_c('div', {
    staticClass: "pa-16px pa-md-24px"
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titWrapSize": "pb-12px pb-md-16px",
      "titWrapAlign": "text-left",
      "titSize": "tit--xs"
    }
  }, [_vm._v("미리보기")]), _c('v-card', [_c('v-textarea', _vm._b({
    attrs: {
      "value": _vm.preview,
      "readonly": "",
      "no-resize": "",
      "rows": "9"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)], 1)]), _c('div', {
    staticClass: "mt-8px mt-md-12px text-right"
  }, [_c('v-chip', {
    attrs: {
      "small": "",
      "color": "primary lighten-5"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("단문 " + _vm._s(_vm.preview.length + 590) + "/1000")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "",
      "order-md": "1"
    }
  }, [_c('v-form', [_c('tit-form-section', {
    attrs: {
      "items": _vm.templateInput
    }
  }, [_c('template', {
    slot: "템플릿명"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "템플릿을 입력해주세요"
    },
    model: {
      value: _vm.template.name,
      callback: function ($$v) {
        _vm.$set(_vm.template, "name", $$v);
      },
      expression: "template.name"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1), _c('template', {
    slot: "제목"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "제목을 입력해주세요"
    },
    model: {
      value: _vm.template.subject,
      callback: function ($$v) {
        _vm.$set(_vm.template, "subject", $$v);
      },
      expression: "template.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('template', {
    slot: "컨텐츠"
  }, [_c('div', [_c('v-textarea', _vm._b({
    attrs: {
      "maxlength": 282,
      "counter": 282,
      "no-resize": "",
      "rows": "9"
    },
    on: {
      "input": _vm.validateContent
    },
    model: {
      value: _vm.template.content,
      callback: function ($$v) {
        _vm.$set(_vm.template, "content", $$v);
      },
      expression: "template.content"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input), false))], 1)]), _c('template', {
    slot: "고객센터"
  }, [_c('div', [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "고객센터",
      "maxlength": 14,
      "counter": 14
    },
    model: {
      value: _vm.template.center,
      callback: function ($$v) {
        _vm.$set(_vm.template, "center", $$v);
      },
      expression: "template.center"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('div', {
    staticClass: "pt-8px pt-md-12px"
  }, [_vm._v(" " + _vm._s(`* 사용자의 이름을 변수로 사용하기 위해서 {이름}의 형태로 컨텐츠 영역에 입력해주시면, 각 문자의 이름이 자동으로 입력됩니다. \n변수 사용 후 실 데이터에 이름 정보가 없는 경우 "고객"으로 표기됩니다.`) + " ")]), _c('div', {
    staticClass: "pt-8px pt-md-12px"
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "value": _vm.preview,
      "readonly": "",
      "no-resize": "",
      "rows": "9"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_controls_common), false))], 1)])], 2)], 1)], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/mypage/template');
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("취소하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-btn--width-fixed",
    attrs: {
      "x-large": ""
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("수정하기")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }